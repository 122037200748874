





























































































































































































































































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IUser } from '@/types/types';
import Vue from 'vue';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { numberFormatter } from '../../filters//number-formatter';
import { dateFormat, truncateContent, loadWidget } from '@/utils/helpers';
import { Getter } from 'vuex-class';

const authModule = namespace('auth');
@Component({
  name: 'ToolBar',
  components: {
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
  },
  filters: {
    numberFormatter,
  },
})
export default class ToolBar extends Vue {
  @Prop({ required: true, default: 'Home' })
  title!: string;

  @Prop({ required: false, default: false })
  showBackButton!: boolean;

  @Prop({ required: false, default: '' })
  backUrl!: string;

  @Prop({ required: false, default: false }) balanceLoading!: boolean;

  @Prop({ required: false, default: 0 }) creditBalance!: number;
  @Prop({ required: false, default: false }) isSubAccountSelected!: boolean;

  @authModule.Getter('getUserDetails') user!: IUser;
  @Getter('getUseIntlRoute') useIntlRoute!: boolean;

  state: boolean = false;
  menu: boolean = false;

  openDrawer(): void {
    this.$store.dispatch('navigationDrawer', !this.state, { root: true });
  }

  refreshBalance(state: boolean): void {
    console.log({ state });
    console.log(this.isSubAccountSelected);
    if (this.isSubAccountSelected) {
      this.$store.dispatch('useIntlRoute', state, { root: true });
      return;
    }
    this.$store.dispatch('useIntlRoute', state, { root: true });
    this.$store.dispatch('checkCreditBalance', null, { root: true });
  }

  @Emit('topUpDialog')
  openTopUpDialog(): boolean {
    return true;
  }

  logOut(): void {
    this.$store.dispatch('auth/logOut', null);
  }
}
