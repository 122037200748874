import { findLast } from 'lodash';
export const numberFormatter = (num: number, digits: number) => {
  const lookup: Array<{ value: number; symbol: string }> = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const item = findLast(lookup, (item) => num >= item.value);
  return item
    ? roundAccurately(num / item.value, digits ?? 2)
        .toString()
        .concat(item.symbol)
    : '0';
};

export const roundAccurately = (number: any, decimalPlaces: any) => {
  return Number(
    Math.round((number + 'e' + decimalPlaces) as any) + 'e-' + decimalPlaces
  );
};
