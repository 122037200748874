var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"white",staticStyle:{"width":"100%","position":"relative","z-index":"100"},attrs:{"light":"","flat":""}},[_c('v-toolbar-title',{staticClass:"ft"},[(!_vm.showBackButton)?_c('v-btn',{staticClass:"mini-screen",attrs:{"icon":"","fab":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openDrawer($event)}}},[_c('img',{staticClass:"rounded-full d-flex flex-row mx-1",staticStyle:{"width":"30px","height":"30px"},attrs:{"src":_vm.user && _vm.user.photo
            ? _vm.user.photo
            : "https://via.placeholder.com/50x50","alt":"Menu Icon"}})]):_vm._e(),(_vm.showBackButton)?_c('v-btn',{staticClass:"mr-4",attrs:{"icon":"","fab":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.$router.push({ name: _vm.backUrl }).catch()}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("arrow_back")])]):_vm._e(),_c('span',{staticClass:"ft toolbar--title font-weight-medium pl-4"},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":!_vm.useIntlRoute ? 'error' : 'primary'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"cursor-pointer",attrs:{"loading":_vm.balanceLoading,"depressed":"","rounded":"","color":!_vm.useIntlRoute ? 'error' : 'grey lighten-3',"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.refreshBalance(false)}}},on),[_c('span',{staticClass:"ft font-weight-bold text-uppercase font-size-sm"},[_vm._v("Local "+_vm._s(_vm._f("numberFormatter")(_vm.creditBalance.credit)))])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Local Credit "+_vm._s(_vm.creditBalance.credit))])]),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.useIntlRoute ? 'error' : 'primary'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1 cursor-pointer",attrs:{"loading":_vm.balanceLoading,"depressed":"","rounded":"","small":"","color":_vm.useIntlRoute ? 'error' : 'grey lighten-3'},on:{"click":function($event){$event.stopPropagation();return _vm.refreshBalance(true)}}},on),[_c('span',{staticClass:"ft font-weight-bold text-uppercase font-size-sm"},[_vm._v("Intl. "+_vm._s(_vm._f("numberFormatter")(_vm.creditBalance.intlCredit)))])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("International Credit "+_vm._s(_vm.creditBalance.intlCredit))])]),_vm._t("actions"),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","fab":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.$store.dispatch(
            'updateDialog',
            { idx: 'help', state: true },
            { root: true }
          )}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("help")])])]}}])},[_c('span',[_vm._v("Help")])]),_c('v-menu',{attrs:{"transition":"slide-y-transition","close-on-content-click":false,"nudge-width":300,"nudge-bottom":"10","max-width":"300","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-avatar',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"link-tag box-shadow-light cursor-pointer hide-links",staticStyle:{"align-items":"center"},attrs:{"href":"#","size":"40","color":_vm.user && _vm.user.photo ? 'white' : 'primary'}},on),[(_vm.user && _vm.user.photo)?_c('img',{staticClass:"rounded-full d-flex flex-column mx-1",staticStyle:{"width":"38px","height":"38px"},attrs:{"src":_vm.user.photo,"alt":""}}):_c('span',{staticClass:"white--text ft font-weight-medium text-uppercase text-md"},[_vm._v(" "+_vm._s(_vm.user.name.charAt(0))+" ")])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"d-flex flex-column cursor-pointer"},[_c('div',{staticClass:"d-flex flex-row mx-2 my-2"},[_c('img',{staticClass:"rounded-full",class:[
            _vm.user && _vm.user.businessId && _vm.user.businessId.isBusinessReseller
              ? 'mt-3'
              : 'mt-0' ],staticStyle:{"width":"50px","height":"50px"},attrs:{"src":_vm.user && _vm.user.photo
              ? _vm.user.photo
              : "https://via.placeholder.com/50x50","alt":""}}),(_vm.user)?_c('div',{staticClass:"d-flex flex-column my-1 mx-2"},[_c('span',{staticClass:"ft",staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.user.name))]),_c('span',{staticClass:"ft font-weight-medium",staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.user.email))]),(
              _vm.user && _vm.user.businessId && _vm.user.businessId.isBusinessReseller
            )?_c('div',{staticClass:"d-flex flex-row"},[_c('v-chip',{staticClass:"mt-1",attrs:{"x-small":"","label":"","color":"primary"}},[_c('span',{staticClass:"ft font-weight-medium text-uppercase",staticStyle:{"font-size":"10px"}},[_vm._v("Reseller")])])],1):_vm._e(),_c('i',{staticClass:"material-icons-outlined verified"},[_vm._v("verified")])]):_vm._e()]),_c('v-divider'),_c('div',[_c('v-list',{staticStyle:{"padding":"0 !important"},attrs:{"dense":""}},[_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"py-1",attrs:{"to":{ name: 'account.profile' }}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("face")])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"ft",staticStyle:{"font-size":"15px"}},[_vm._v("My Profile ")])],1)],1)],1)],1),_c('v-divider'),_c('div',[_c('v-list',{staticStyle:{"padding":"0 !important"},attrs:{"dense":""}},[_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"py-1",attrs:{"to":{ name: 'account.change.password' }}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("lock")])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"ft",staticStyle:{"font-size":"15px"}},[_vm._v("Change Password ")])],1)],1)],1)],1),_c('v-divider'),_c('div',[_c('v-list',{staticStyle:{"padding":"0 !important"},attrs:{"dense":""}},[_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"py-1"},[_c('v-list-item-content',{staticStyle:{"width":"100%"}},[_c('v-list-item-title',{staticClass:"ft font-weight-medium",staticStyle:{"font-size":"15px"},on:{"click":_vm.logOut}},[_vm._v("Log out ")])],1)],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }